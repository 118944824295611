/* You can add global styles to this file, and also import other style files */
@import "~material-design-icons/iconfont/material-icons.css";
@import "colors.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Buttons
button {
  margin-right: 0.5em !important;
  padding-right: 0.5em !important;
  padding-left: 0.5em !important;
}

.matchingActionButtons > button {
  width: auto;
}

.small-icon-button {
  width: 29px !important;
  height: 29px !important;
  line-height: 29px !important;

  .mat-icon {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
  }
  .material-icons {
    font-size: 20px !important;
  }
}

// Radio Buttons
mat-radio-button {
  margin: 0.5em 1em 0.5em 0;
}

.labelRadioGroup {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.labelRadioGroup > label {
  font-weight: bold;
}

//General
.flexWrapper {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.fullwidth {
  width: 100%;
}

.column-25 {
  float: left;
  width: 25%;
}

.column-33 {
  float: left;
  width: 33%;
}

.column-33-fit-content {
  float: left;
  width: 33%;
  height: fit-content;
}

.column-50 {
  float: left;
  width: 50%;
}

.column-66 {
  float: left;
  width: 66%;
}

.column-75 {
  float: left;
  width: 75%;
}

.column-100 {
  float: left;
  width: 100%;
}

.JustifyContentflexEnd {
  justify-content: flex-end;
}

.buttonSpacingRight {
  margin-right: 1rem !important;
}

.columnPaddingRight {
  padding-right: 2.5rem;
}

.columnPadding {
  padding: 1em;
}

.columnPaddingNoTop {
  padding: 0 1em 1em;
}

.booleanSearchRowPadding {
  padding: 6px;
  margin-left: 0px;
  margin-right: 0px;
}

.quickSearchPadding {
  padding-top: 12px;
}

.k-button {
  padding: 5px;
}

.mat-form-field {
  width: 100%;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 3em;
}

.alignItems-Center {
  align-items: center;
}

.mat-divider {
  border-top-color: #99051d;
  border-top-width: 100px;
  border-width: 100px;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

// Cards
.mat-card {
  padding: 0px 10px;
  margin: 5px;
}

.searchAttributeCard {
  display: flex !important;
  flex-direction: column;
  min-height: 100%;
}

.custom.mat-mdc-card {
  padding: 8px;
}

.flexible {
  flex-grow: 1;
}

.marginTop-2 {
  margin-top: 2rem !important;
}

.StandardMarginLeft {
  margin-left: 1em !important;
}

.quickSearchBox {
  float: left;
  width: 49%;
}

.row {
  flex-wrap: inherit;
  margin-left: initial;
  margin-right: initial;
}

.rapportageObjectTypes > .row > * {
  flex-shrink: 1;
}

.quickSearchForm > .row > * {
  padding-right: 0;
  padding-left: 0;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.no-bullet {
  list-style-type: none;
}

.spaceBetweenButtons {
  margin: 0 1em;
}

button:focus {
  outline: none;
}

.custom.mat-mdc-card:first-child {
  padding-left: 6px;
}

.custom.mat-mdc-card:last-child {
  padding-right: 0;
}

//Menu
.menuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-bottom: 20px;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

mat-sidenav {
  background-color: $gray-lighter;
}

.logo {
  background-image: url("../assets/img/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 auto;
  width: 100px;
  height: 75px;
}

// Header
.mat-mdc-list-base {
  display: flex !important;
  align-items: center;
  padding-top: 0px !important;
}

mat-nav-list {
  flex-direction: column;
}

.mat-toolbar {
  background-color: $white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 50px;
}

.spacer {
  flex: 1 1 auto;
}

.horizontalSpacer {
  flex-grow: 1;
}

.header-item {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-item > button {
  height: inherit;
}

mat-sidenav-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 0px !important;
  padding-left: 5em;
}

mat-toolbar {
  text-align: right;
}

.sidenav-item {
  color: $graphite;
  font-size: 14px;
}

mat-list-item > mat-icon {
  color: $graphite;
}

router-outlet {
  padding-right: 2em;
}

#content {
  padding: 1rem;
  padding-top: 0;
}

.removeContentMarginTop {
  margin-top: -1rem;
}

//Dialog
.dialog-header {
  display: flex;
}

.dialog-header > span {
  cursor: pointer;
}

//Form
form > p > button > mat-icon {
  color: $white;
}

.confirmDeleteBox {
  padding: 0 24px;
}

.mat-mdc-form-field {
  width: 100%;
}

//Kendo Grid

.k-grid-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row > .k-table-th,
.k-filter-row > td,
.k-filter-row > .k-table-td {
  padding: 0 5px;
  margin: 0;
  white-space: nowrap;
}

.k-grid .k-table-th > .k-radio,
.k-grid .k-table-th > .k-radio-wrap,
.k-grid .k-table-th > .k-checkbox,
.k-grid .k-table-th > .k-checkbox-wrap,
.k-grid td > .k-radio,
.k-grid td > .k-radio-wrap,
.k-grid td > .k-checkbox,
.k-grid td > .k-checkbox-wrap,
.k-grid .k-table-td > .k-radio,
.k-grid .k-table-td > .k-radio-wrap,
.k-grid .k-table-td > .k-checkbox,
.k-grid .k-table-td > .k-checkbox-wrap {
  vertical-align: inherit;
}

.k-checkbox,
.k-checkbox:checked,
.k-checkbox.k-checked {
  width: 1rem;
  height: 1rem;
}

.k-grid-md .k-filter-row td,
.k-grid-md .k-filter-row .k-table-td,
.k-grid-md .k-filter-row .k-table-th {
  padding-block: 0;
  padding-inline: 0;
}

.k-grid {
  width: 100%;
}

.k-grid-toolbar {
  height: 4em;
}

.k-grid th {
  padding: 6px 8px;
}

.k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
.k-grid .k-command-cell,
.k-grid .k-edit-cell {
  padding: 0;
}

.k-grid .k-hierarchy-cell > .k-icon,
.k-grid .k-drag-cell > .k-icon {
  padding: 0;
}

.k-pager-wrap {
  padding: 0 5px;
}

.k-column-resizer:hover {
  color: $brand-primary !important;
}

.k-grid-header .k-grid-filter.k-state-active,
.k-grid-header .k-header-column-menu.k-state-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-state-active {
  background: $brand-primary-dark !important;
}

.k-link {
  color: $black !important;
}

.k-link:hover {
  color: $white !important;
  background-color: $brand-primary !important;
}

.k-link.k-state-selected {
  color: $white !important;
  background-color: $brand-primary !important;
}

.k-grid-header .k-header > .k-link {
  margin: -36px -24px;
  padding: 30px 24px;
}

kendo-grid-column-menu > .k-grid-column-menu {
  padding-top: 16px !important;
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textbox.k-state-focused {
  border-color: $brand-primary !important ;
  border-top: none;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: $brand-primary !important;
}

// Material Select/Option
.mdc-menu-surface.mat-mdc-select-panel {
  background-color: $white;
}

.mat-mdc-autocomplete-panel {
  background-color: $white;
}

// Material Snackbar
.snackbar-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.snackbar-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.snackbar-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.mat-mdc-snack-bar-action {
  color: inherit;
}

// Job Upload
.uploadList {
  margin-top: 1rem;
  list-style-image: url("./upload_file-black-18dp.svg");
}

.localObjectTypesList {
  margin-top: 1rem;
  list-style-type: none;
}

.invalidFilesList {
  list-style-type: none;
}

.errorLabel {
  color: red;
  font-weight: bold;
}

// Overlay
.overlayCard {
  position: absolute !important;
  z-index: 1;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0.7;
  z-index: 10000;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  position: relative;
}

// Version style homepage
.version-bottom-center {
  text-align: center;
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
