// General
$black: #000000;
$graphite: #333333;
$white: #ffffff;

//ProRail
$brand-primary: #b20a2f;
$brand-primary-light: #f75262;
$brand-primary-dark: #860013;
$brand-secondary: #f1e700;
$gray-darker: #2c2a3b;
$gray-dark: #535266;
$gray: #a5a2b8;
$gray-light: #e6e6ed;
$gray-lighter: #f8f8f8;
